<template>
    <v-container
    id="vendor-appointments"
    fluid
    tag="section"
  >
      <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Vendor Appointments <strong>saved</strong> successfully.
    </v-alert>
    <v-row justify="center">
        <v-col
        cols="12"
        md="12"
        >
                <v-col cols="12" class="text-right">
              <v-btn color="success" class="mr-0"
              @click="updateSection()">Save</v-btn>
                    </v-col>
            <base-material-card>
            <template v-slot:heading>
                <div class="display-2 font-weight-light">
                Vendors
                </div>
              <div class="display-2 font-weight-light">
                Schedule Appointments with the information listed below:
                </div>
            </template>
            <v-form>
                <v-container class="py-0">
                    <v-row>

                        <v-col cols="6">
                            <v-card>
                                <v-card-text>
                                  <v-col cols="6" md="12" align="right">
                                  <v-header>SECTION A</v-header>
                                  </v-col>
                                  <v-col cols="12" align="left">
                                  <v-header><strong>SCHEDULE 1st</strong></v-header>
                                  </v-col>
                                        <v-row>
                                          <v-col cols="6" md="12" align="center">
                                          <h2>SUPERIOR FLOORING</h2>
                                            <u><strong>superiorflooringcorp.com</strong></u>
                                            <p>2053 Old Highway 8 N.W.<br>
                                            New Brighton, MN 55112</p>
                                            <h4>651-631-8272</h4>
                                            <p>(schedule w/Jennifer Barger)<br>
                                              <em>jennifer@superiorflooringcorp.com</em><br>
                                              *Appointments may be scheduled Monday, Tuesday, and Wednesday with Tuesday evenings available.<br>
                                              </p>
                                            <h4>*Select All Flooring, Bathroom tiles, All countertops and backsplash, paint and stain colors.</h4><br>
                                          </v-col>
                                          <v-row><br>
                                            <v-row>
                                            <v-col cols="5" offset-md="1" align="center">
                                      <h3>Day of Appointment: <v-text-field type="date" align="right" v-model="section.column_1"/></h3>
                                            </v-col>
                                            </v-row>
                                          </v-row>

                                        </v-row>

                                </v-card-text>

                            </v-card>
                        </v-col>
                      <v-col cols="6">
                            <v-card>
                                <v-card-text>
                                  <v-col cols="12" align="right">
                                  <v-header>SECTION B</v-header>
                                  </v-col>
                                  <v-col cols="12" align="left">
                                  <v-header><strong>SCHEDULE 2nd</strong></v-header>
                                  </v-col>
                                        <v-row>
                                          <v-col cols="12" md="12" align="center">
                                          <h2>ULTIMATE CABINETRY</h2>
                                            <u><strong>ultimatecabinetry.com</strong></u>
                                            <p>13567 Thrush St. N.W. <br>
                                            Andover, MN 55304</p>
                                            <h4>To schedule appt call: 763-767-3123 (Keith Gerads)</h4>
                                            <p>*Appointments may be scheduled: <br>
                                              <em>Monday, Tuesday & Thursdays 6am-3:30pm Wednesdays 6am-5pm </em><br>
                                              (Allow 1-2 hours for appointment)<br>
                                              </p>
                                            <h4>*Select All Cabinetry</h4><br>
                                          </v-col>
                                          <v-row>
                                            <v-row>
                                           <v-col cols="5" offset-md="1" align="center">
                                      <h3>Day of Appointment: <v-text-field type="date" align="right" v-model="section.column_3"/></h3>
                                            </v-col>
                                            </v-row>
                                          </v-row>

                                        </v-row>

                                </v-card-text>

                            </v-card>
                        </v-col>
                      <v-col cols="6">
                            <v-card>
                                <v-card-text>
                                 <v-col cols="12" align="right">
                                  <v-header>SECTION 2</v-header>
                                  </v-col>
                                  <v-col cols="12" align="left">
                                  <v-header><strong>SCHEDULE 3rd</strong></v-header>
                                  </v-col>
                                        <v-row>
                                          <v-col cols="12" md="12" align="center">
                                          <h2>M.J.’s CONTRACT APPLIANCE</h2>
                                            <u><strong>mjsappliance.com</strong></u>
                                            <p>6922 55th Street N.  <br>
                                            Oakdale, MN 55128</p>
                                            <h4>651-777-8915</h4>
                                            <p><br>
                                              <em></em><br>
                                              <br>
                                              </p>
                                            <h4></h4><br><br><br>
                                          </v-col>
                                          <v-row>
                                            <v-row>
                                           <v-col cols="5" offset-md="1" align="center">
                                      <h3>Day of Appointment: <v-text-field type="date" align="right" v-model="section.column_5"/></h3>
                                            </v-col>
                                              <v-col cols="5" align="center">
                                            </v-col>
                                            </v-row>
                                          </v-row>

                                        </v-row>

                                </v-card-text>

                            </v-card>
                        </v-col>
                      <v-col cols="6">
                            <v-card>
                                <v-card-text>
                                 <v-col cols="12" align="right">
                                  <v-header>SECTION C</v-header>
                                  </v-col>
                                  <v-col cols="12" align="left">
                                  <v-header><strong>SCHEDULE 4th</strong></v-header>
                                  </v-col>
                                        <v-row>
                                          <v-col cols="12" md="12" align="center">
                                          <h2>43rd STREET LIGHTING</h2>
                                            <u><strong>43rdstreetlighting.com</strong></u>
                                            <p>9636 85th Avenue N. <br>
                                            Maple Grove, MN 55369 </p>
                                            <h4>763-424-3736</h4>
                                            <p>(schedule w/Cathy Lutz)<br>
                                              <em>*Also, select: Optional framed bathroom mirrors</em><br>
                                              <br>
                                              </p>
                                            <h4></h4><br><br><br>
                                          </v-col>
                                          <v-row>
                                            <v-row>
                                           <v-col cols="5" offset-md="1" align="center">
                                      <h3>Day of Appointment: <v-text-field type="date" align="right" v-model="section.column_7"/></h3>
                                            </v-col>
                                              <v-col cols="5" align="center">
                                            </v-col>
                                            </v-row>
                                          </v-row>

                                        </v-row>

                                </v-card-text>

                            </v-card>
                        </v-col>
                      <v-col cols="6">
                            <v-card>
                                <v-card-text>
                                <v-col cols="12" align="right">
                                  <v-header>SECTION D</v-header>
                                  </v-col>
                                  <v-col cols="12" align="left">
                                  <v-header><strong>SCHEDULE 5th</strong></v-header>
                                  </v-col>
                                        <v-row>
                                          <v-col cols="12" md="12" align="center">
                                          <h2>CONDOR stone fireplaces design</h2>
                                            <u><strong>condorfireplace.com</strong></u>
                                            <p>8282 Arthur St. NE.<br>
                                            Spring Lake Park, MN 55432 </p>
                                            <h4>Contact: Shelly Bowen or Ryan Thiedd 763-786-2341)</h4>
                                            <p><br>
                                              <em>*Select exterior and interior fireplace stone </em><br>
                                              <br>
                                              </p>
                                            <h4></h4><br><br>
                                          </v-col>
                                          <v-row>
                                            <v-row>
                                           <v-col cols="5" offset-md="1" align="center">
                                      <h3>Day of Appointment: <v-text-field type="date" align="right" v-model="section.column_9"/></h3>
                                            </v-col>
                                              <v-col cols="5" align="center">
                                            </v-col>
                                            </v-row>
                                          </v-row>

                                        </v-row>

                                </v-card-text>

                            </v-card>
                        </v-col>
                      <v-col cols="6">
                            <v-card>
                                <v-card-text>
                               <v-col cols="12" align="right">
                                  <v-header>SECTION B</v-header>
                                  </v-col>
                                  <v-col cols="12" align="left">
                                  <v-header><strong></strong></v-header>
                                  </v-col>
                                        <v-row>
                                          <v-col cols="12" md="12" align="center">
                                          <h3>[*OPTIONAL w/GRANITE UPGRADE*] EXQUISITE STONE</h3>
                                            <u><strong>condorfireplace.com</strong></u>
                                            <p>15560 Cleveland Street <br>
                                            Elk River, MN 55330 </p>
                                            <h4>763-633-3500</h4>
                                            <p><br>
                                              <em>(To view optional granite countertops up and above standard allowance at flooring studio)</em><br>
                                              <br>
                                              </p>
                                            <h4>(Will be in slab form) (Homeowner’s responsibility)</h4><br><br>
                                          </v-col>
                                          <v-row>
                                            <v-row>
                                           <v-col cols="5" offset-md="1" align="center">
                                      <h3>Day of Appointment: <v-text-field type="date" align="right" v-model="section.column_11"/></h3>
                                            </v-col>
                                              <v-col cols="5" align="center">
                                            </v-col>
                                            </v-row>
                                          </v-row>

                                        </v-row>

                                </v-card-text>

                            </v-card>
                        </v-col>
                      <v-col cols="6">
                            <v-card>
                                <v-card-text>
                              <v-col cols="12" align="right">
                                  <v-header>SECTION E</v-header>
                                  </v-col>
                                  <v-col cols="12" align="left">
                                  <v-header><strong></strong></v-header>
                                  </v-col>
                                        <v-row>
                                          <v-col cols="12" md="12" align="center">
                                          <h3>[*OPTIONAL VENDOR*] GLASS & MIRROR</h3>
                                            <u><strong></strong></u>
                                            <p>10001 Baltimore Street N.E. <br>
                                            Blaine, MN 55449</p>
                                            <h4>763-450-1661</h4>
                                            <p><br>
                                              <em>(schedule w/Jordan Morell)</em><br>
                                              <br>
                                              </p>
                                            <h4>*Optional unless Homeowner chooses upgrade above standard frameless mirrors, and any shower glass needs.</h4><br><br>
                                          </v-col>

                                        </v-row>

                                </v-card-text>

                            </v-card>
                        </v-col>
                      <v-col cols="6">
                            <v-card>
                                <v-card-text>
                            <v-col cols="12" align="right">
                                  <v-header>SECTION F</v-header>
                                  </v-col>
                                  <v-col cols="12" align="left">
                                  <v-header><strong></strong></v-header>
                                  </v-col>
                                        <v-row>
                                          <v-col cols="12" md="12" align="center">
                                          <h3>[*OPTIONAL VENDOR*] SHERWIN WILLIAMS</h3>
                                            <u><strong></strong></u>
                                            <p>(Any location)<br>
                                            For viewing larger selection only</p>
                                            <h4></h4>
                                            <p><br>
                                              <em></em><br>
                                              <br>
                                              </p>
                                            <h4>*Paint selections are completed at Superior Flooring</h4><br><br><br><br>
                                          </v-col>

                                        </v-row>

                                </v-card-text>

                            </v-card>
                        </v-col>
                      <v-col cols="6">
                            <v-card>
                                <v-card-text>
                          <v-col cols="12" align="right">
                                  <v-header>SECTION G</v-header>
                                  </v-col>
                                  <v-col cols="12" align="left">
                                  <v-header><strong></strong></v-header>
                                  </v-col>
                                        <v-row>
                                          <v-col cols="12" md="12" align="center">
                                          <h3>[*OPTIONAL VENDOR*] FERGUSON PLUMBING</h3>
                                            <u><strong></strong></u>
                                            <p>590 Hale Avenue N.<br>
                                            Oakdale, MN 55128</p>
                                            <h4>612-851-4707 Or 651-779-9393</h4>
                                            <p><br>
                                              <em>(schedule w/Ronda Ramstad)</em><br>
                                              <br>
                                              </p>
                                            <h4>*Optional unless Homeowner chooses upgrade above standard Delta package</h4><br>
                                          </v-col>
                                        </v-row>
                                </v-card-text>

                            </v-card>
                        </v-col>
                      <v-col cols="6">
                            <v-card>
                                <v-card-text>
                         <v-col cols="12" align="right">
                                  <v-header>SECTION 1 & 2</v-header>
                                  </v-col>
                                  <v-col cols="12" align="left">
                                  <v-header><strong></strong></v-header>
                                  </v-col>
                                        <v-row>
                                          <v-col cols="12" md="12" align="center">
                                          <h3>[*OPTIONAL VENDOR*]NORTH METRO SUPPLY</h3>
                                            <u><strong></strong></u>
                                            <p>(View only at <a href='www.northmetrosupplies.com' target='_blank'>www.northmetrosupplies.com</a>)<br>
                                            Ham Lake, MN 55304</p>
                                            <h4></h4>
                                            <p><br>
                                              <em>*Exterior/Interior doors, hardware, windows and millwork</em><br>
                                              <br>
                                              </p>
                                            <h4>*Selections made w/Boulder Contracting</h4><br><br>
                                          </v-col>
                                        </v-row>

                                </v-card-text>

                            </v-card>
                        </v-col>
                      <v-col cols="6">
                            <v-card>
                                <v-card-text>
                         <v-col cols="12" align="right">
                                  <v-header>SECTION K</v-header>
                                  </v-col>
                                  <v-col cols="12" align="left">
                                  <v-header><strong></strong></v-header>
                                  </v-col>
                                        <v-row>
                                          <v-col cols="12" md="12" align="center">
                                          <h3>[*OPTIONAL VENDOR*] WEBCO Home Security</h3>
                                            <u><strong></strong></u>
                                            <p>901 West Main Street, Suite B-104 <br>
                                            Anoka, MN 55303</p>
                                            <h4>763-767-7229</h4>
                                            <p>(schedule w/Randy Stefanick) <br>
                                              <em>(Specialty Wiring, Security Systems, Home Theater, Central Vac)</em><br>
                                              <br>
                                              </p>
                                            <h4>*Schedule 2 weeks prior to your electrical walk through w/builder at the new home</h4><br><br>
                                          </v-col>
                                        </v-row>

                                </v-card-text>

                            </v-card>
                        </v-col>
                      <v-col cols="6">
                            <v-card>
                                <v-card-text>
                        <v-col cols="12" align="right">
                                  <v-header>SECTION L</v-header>
                                  </v-col>
                                  <v-col cols="12" align="left">
                                  <v-header><strong></strong></v-header>
                                  </v-col>
                                        <v-row>
                                          <v-col cols="12" md="12" align="center">
                                          <h3>[*OPTIONAL VENDOR*] JANISH WOOD PRODUCTS</h3>
                                            <u><strong></strong></u>
                                            <p>16030 Central Avenue N.E.  <br>
                                            Ham Lake, MN 55304</p>
                                            <h4>763-434-9090</h4>
                                            <p><br>
                                              <em>(for solid surface vanity countertops)</em><br>
                                              <br>
                                              </p>
                                            <h4>*Countertop selections to be completed at Superior Flooring</h4><br><br>
                                          </v-col>
                                        </v-row>

                                </v-card-text>

                            </v-card>
                        </v-col>
                      <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                        <v-row>
                                          <v-col cols="12" md="12" align="center">
                                          <h1>**Bring your house drawing and all 17 pages of these selection sheets to your appointments, Thank you!</h1>
                                          </v-col>
                                        </v-row>

                                </v-card-text>

                            </v-card>
                        </v-col>
                      <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection()">Save</v-btn>
                    </v-col>
                    </v-row>
                </v-container>
            </v-form>
            </base-material-card>
        </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";

export default {
    name:'VendorSelectionsVendorAppointments',
    data () {
        return {
        email: '',
        rules: {
            required: value => !!value || 'Required.',
            email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
            },
        },
          home_id: localStorage.getItem("homeid"),
        alert:false,
        section_id: "VA",
        section: {
          column_1:'',
          column_2:'',
          column_3:'',
          column_4:'',
          column_5:'',
          column_6:'',
          column_7:'',
          column_8:'',
          column_9:'',
          column_10:'',
          column_11:'',
          column_12:''
        }
        }
    },
  created () {
    this.getSection()
    },
    methods: {

      getSection() {
        axios
            .get(process.env.VUE_APP_GET_VENDOR_SELECTIONS_URL,
                {
                  params: {
                    home_id: this.home_id,
                    section_id: this.section_id
                  }
                })
            .then(response => {

              if (response.data) {
                var result = response.data
                this.section = JSON.parse(result['JsonDetails'])
              }
            })
            .catch(error => {
              console.log(error)
            })
      },
      updateSection() {
        var body = {
          'home_id': this.home_id,
          'section_id': this.section_id,
          "section": JSON.stringify(this.section),
        }

        axios
            .post(process.env.VUE_APP_UPDATE_VENDOR_SELECTIONS_URL, body)
            .then(() => {
              this.alert = true
            })
            .catch(error => {
              console.log(error)
            })
      }
    }
}
</script>